class BaseItem extends HTMLElement {
    constructor(data) {
        super();
        this.data = data;
        this.addEventListener('click', this.onClick.bind(this));
    }

    onClick() {
        if (this.allow_iframe) {
            const content = document.getElementById("iframe-content");
            content.src = this.data.url;
            // Imposto z-index altrimenti non sarebbe visibile (..)
            content.classList.add('z-40');
            content.classList.remove('hidden');
            // Rimuovo contenuto centrale
            document.getElementById("center-column").classList.remove("hidden");
            // Per mobile: rimuovo anche colonne laterali
            if (window.innerWidth <= 768) {
                Array.prototype.forEach.call(document.getElementsByClassName("side-menu"), function(element) {
                    element.classList.add("hidden");
                });
            }

            // Nota: Le cose nascoste ritorneranno solo ricaricando la pagina
        } else {
            // Apertura in una nuova scheda se non è possibile incorporare
            window.open(this.data.url, '_blank');
        }
        
        // Rimozione margine per browser Safari su mobile
        const pageContent = document.getElementById("page-content");
        pageContent.style.height = '';
        document.body.scrollTop = 0;

        // Rimozione banner con notizie in primo piano
        hideBanner();
        
        // Aggiornamento contatore per l'URL
        fetch('/update?url=' + encodeURI(this.data.url));
    }

    get allow_iframe() {
        if (this.data.url.includes('t.me')) return false;
        if (this.data.url.includes('youtube.com')) return false;
        if (this.data.url.includes('paypal.com')) return false;
        return this.data.allows_iframe;
    }
};


window.customElements.define('base-item', BaseItem);